
import { mapGetters, mapState } from 'vuex'
import { BIconX } from 'bootstrap-vue'
import Navbar from '~/components/layout/navbar.vue'
import Footer from '~/components/layout/footer.vue'
import CategoryMenu from '~/components/layout/category-menu.vue'

export default {
  // middleware: 'i18n',
  components: {
    Navbar,
    Footer,
    BIconX,
    CategoryMenu
  },
  data () {
    return {
      loadingModal: false,
      loadingModalCheckprice: false
    }
  },
  computed: {
    ...mapState(['loading', 'alert', 'loadingprice', 'alertWithLine']),
    ...mapGetters(['isAuthenticated'])
  },
  created () {
    this.loadingModal = false
  },
  watch: {
    loading (val) {
    //   console.log('***>>> loading <<<***', val)
      this.loadingModal = val
      if (!val) {
        // console.log('##### hideeeeee ####')
        this.$refs.modalLoading.hide()
      }
    },
    loadingprice (val) {
      this.loadingModalCheckprice = val
    },
    '$route.name' (value) {
      this.showModalNewCustomerPoints()
      this.showModalOldCustomerPoints()
    }
  },
  mounted () {
    this.showModalNewCustomerPoints()
    this.showModalOldCustomerPoints()
    this.showModalAddLine()
  },
  methods: {
    hideAlert () {
      this.$store.commit('setAlert', { show: false, message: '' })
    },
    hideModalAlertLine () {
      this.$store.commit('setAlertWithLine', { show: false, message: '' })
    },
    hideLoading () {
      this.$store.commit('hideLoading')
    },
    hideLoadingCheckPrice () {
      this.$store.commit('hideLoadingCheckPrice')
    },
    showModalAddLine () {
      const routes = [
        this.localePath('index')
      ]
      const curHref = this.$router.resolve({ name: this.$nuxt.$route.name }).href

      if (routes.includes(curHref)) {
        let addLinePopup =
          localStorage.getItem('addLinePopup') || null
        if (addLinePopup) {
          const now = new Date()
          if (now.getTime() <= addLinePopup) {
            return false
          } else {
            const time = now.getTime() + 1800000
            localStorage.setItem('addLinePopup', time)
          }
        } else {
          const now = new Date()
          const time = now.getTime() + 1800000
          addLinePopup = [{ popup: 'true', time }]
          localStorage.setItem('addLinePopup', time)
        }
        this.$refs['modal-add-line'].show()
      }
    },
    showModalNewCustomerPoints () {
      const routes = [
        this.localePath('auth-login-via-phone'),
        this.localePath('auth-register'),
        this.localePath('auth-reset-password'),
        this.localePath('checkout-bank-transfer-finished-id'),
        this.localePath('checkout-credit-card-finished'),
        this.localePath('checkout-id'),
        this.localePath('my-profile'),
        this.localePath('my-orders'),
        this.localePath('my-orders-orderId'),
        this.localePath('my-cart'),
        this.localePath('products-detail-id'),
        this.localePath('line-link'),
        this.localePath('index')
      ]
      const curHref = this.$router.resolve({ name: this.$nuxt.$route.name }).href

      if ((curHref === this.localePath('customize-product-design-id') || curHref === this.localePath('products-detail-id')) &&
          (localStorage.getItem('myZipcode') === null || localStorage.getItem('myZipcode') === '')
      ) {
        return false
      }
      if (!routes.includes(curHref) && !this.isAuthenticated) {
        let newCustomerPointsPopup =
          localStorage.getItem('newCustomerPointsPopup') || null
        if (newCustomerPointsPopup) {
          const now = new Date()
          if (now.getTime() <= newCustomerPointsPopup) {
            return false
          } else {
            const time = now.getTime() + 1800000
            localStorage.setItem('newCustomerPointsPopup', time)
            localStorage.setItem('oldCustomerPointsPopup', time)
          }
        } else {
          const now = new Date()
          const time = now.getTime() + 1800000
          newCustomerPointsPopup = [{ popup: 'true', time }]
          localStorage.setItem('newCustomerPointsPopup', time)
          localStorage.setItem('oldCustomerPointsPopup', time)
        }
        this.$refs['modal-new-customer-points'].show()
      }
    },
    showModalOldCustomerPoints () {
      const routes = [
        this.localePath('auth-login-via-phone'),
        this.localePath('auth-register'),
        this.localePath('auth-reset-password'),
        this.localePath('checkout-bank-transfer-finished-id'),
        this.localePath('checkout-credit-card-finished'),
        this.localePath('checkout-id'),
        this.localePath('my-profile'),
        this.localePath('my-orders'),
        this.localePath('my-orders-orderId'),
        this.localePath('my-cart'),
        this.localePath('line-link'),
        this.localePath('index')
      ]
      const curHref = this.$router.resolve({ name: this.$nuxt.$route.name }).href
      if ((curHref === this.localePath('customize-product-design-id') || curHref === this.localePath('products-detail-id')) &&
          (localStorage.getItem('myZipcode') === null || localStorage.getItem('myZipcode') === '')
      ) {
        return false
      }

      if (!routes.includes(curHref) && this.isAuthenticated) {
        let oldCustomerPointsPopup =
          localStorage.getItem('oldCustomerPointsPopup') || null
        if (oldCustomerPointsPopup) {
          const now = new Date()
          if (now.getTime() <= oldCustomerPointsPopup) {
            return false
          } else {
            const time = now.getTime() + 1800000
            localStorage.setItem('oldCustomerPointsPopup', time)
          }
        } else {
          const now = new Date()
          const time = now.getTime() + 1800000
          oldCustomerPointsPopup = [{ popup: 'true', time }]
          localStorage.setItem('oldCustomerPointsPopup', time)
        }
        this.$refs['modal-old-customer-points'].show()
      }
    }
  }
}
